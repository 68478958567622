/* eslint-disable camelcase */
import React from "react";
import Flex from "app/components/Flex";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";

export const HeadlineSkeleton = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" mr="8px">
      <SkeletonText width="286px" height="24px">
          <SkeletonTextLoader width="100%" height="100%"/>
      </SkeletonText>
      <SkeletonText
        width="299px"
        height="12px"
        mt="16px"
      >
        <SkeletonTextLoader width="100%" height="100%"/>
      </SkeletonText>
    </Flex>
  );
};
