import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Icon, { Check } from "app/components/Icon";
import Loader from "app/components/Loader";
import Modal from "app/components/Modal";
import {
  ModalHeader,
  ModalWrapper,
  ModalBody,
} from "app/components/Modal/components";
import { H2, H3, P1 } from "app/components/Typography";

const WEEKLY_STREAK_GOAL_OPTIONS = [1, 2, 3, 4, 5, 6, 7];

const WeeklyStreakModal = ({
  currentGoal,
  updateGoal,
  isOpen,
  toggleModal,
}) => {
  const [updatingGoalTo, setUpdatingGoalTo] = useState(null);

  useEffect(() => {
    setUpdatingGoalTo(null);
  }, [currentGoal]);

  return (
    <Modal maxWidth="400px" p={0} isOpen={isOpen}>
      <ModalWrapper>
        <ModalHeader p={3} toggleModal={() => toggleModal(false)}>
          <H2 color="white">Weekly Dance Goal</H2>
        </ModalHeader>
        <ModalBody p={3}>
          <Flex flexDirection="column">
            <H3 mb={2} color="blue">
              How many days do you want to dance a week?
            </H3>
            <P1>
              Set your dance goal. You will gain a weekly streak when you
              achieve your dance goal!
            </P1>
            {WEEKLY_STREAK_GOAL_OPTIONS.map(goalOption => {
              const isSelectedGoal = currentGoal === goalOption;
              const willBeSelectedGoal = updatingGoalTo === goalOption;

              return (
                <Flex
                  my={3}
                  alignItems="center"
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={() => {
                    if (isSelectedGoal) {
                      return;
                    }

                    setUpdatingGoalTo(goalOption);
                    updateGoal(goalOption);
                  }}
                >
                  <P1 color={isSelectedGoal ? "blue" : "black"}>
                    {(() => {
                      if (goalOption === 7) {
                        return "Every Day";
                      }
                      if (goalOption === 1) {
                        return `${goalOption} Day a week`;
                      }
                      return `${goalOption} Days a week`;
                    })()}
                  </P1>

                  {isSelectedGoal && !updatingGoalTo && (
                    <Icon width="15px" as={Check} color="blue" />
                  )}

                  {willBeSelectedGoal && <Loader width="20" />}
                </Flex>
              );
            })}
          </Flex>
        </ModalBody>
      </ModalWrapper>
    </Modal>
  );
};

WeeklyStreakModal.propTypes = {
  currentGoal: PropTypes.number.isRequired,
  updateGoal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default WeeklyStreakModal;
