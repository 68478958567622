import React from "react";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import { SkeletonPreviewAnimation } from "app/components/SkeletonAnimation";
import { ScheduleAdd, VolumeOn } from "app/components/Icon";
import { OverlayShadow } from "app/components/ClassCard/Card/styles";
import CardIconButton from "app/components/Button/IconButton/CardIconButton";
import { IS_MOBILE } from "constants/index";

export const SchedulePreviewSkeleton = () => {
  if (IS_MOBILE) {
    return null;
  }

  return (
    <Flex
      background="white"
      width="100%"
      minHeight={{ sm: "300px", md: "500px" }}
      overflow="hidden"
      position="relative"
      zIndex="-1"
    >
      <Div zIndex="1" position="absolute" width="100%" height="100%">
        <SkeletonPreviewAnimation />
      </Div>
      <Flex
        width="100%"
        height="100%"
        background="linear-gradient(rgba(34, 34, 34, 0.1) 15%, rgba(34, 34, 34, 0.3) 60%, rgb(34, 34, 34, 0.6) 80%, rgb(34, 34, 34) 100%);"
        overflow="hidden"
        position="relative"
        zIndex="2"
      >
        <Div width="100%" pb={{ lg: "56.25%" }}>
          <Div width="100%" height="100%" position={{ lg: "absolute" }} top={0}>
            <Div height="100%">
              <OverlayShadow height="30%" />
              <Flex
                position="absolute"
                bottom={0}
                width="100%"
                justifyContent="space-between"
                p={4}
                alignItems="flex-end"
              >
                <Flex flexDirection="column">
                  <SkeletonText
                    width={{ sm: "186px", md: "236px", lg: "286px" }}
                    height="24px"
                    mb="18px"
                  >
                    <SkeletonTextLoader
                      width={{ sm: "540px", md: "915px" }}
                      height="100%"
                    />
                  </SkeletonText>
                  <SkeletonText
                    width={{ sm: "59px", md: "109px", lg: "159px" }}
                    height="12px"
                    mb="12px"
                  >
                    <SkeletonTextLoader
                      width={{ sm: "540px", md: "915px" }}
                      height="100%"
                    />
                  </SkeletonText>
                  <SkeletonText
                    width={{ sm: "129px", md: "179px", lg: "229px" }}
                    height="12px"
                    mb="12px"
                  >
                    <SkeletonTextLoader
                      width={{ sm: "540px", md: "915px" }}
                      height="100%"
                    />
                  </SkeletonText>
                </Flex>
                <Div>
                  <Flex justifyContent="flex-end">
                    <CardIconButton color="white" Icon={ScheduleAdd} mr={3} />
                    <CardIconButton color="white" Icon={VolumeOn} />
                  </Flex>
                  <SkeletonText
                    position="relative"
                    mt={3}
                    width={{ sm: "145px", md: "195px", lg: "245px" }}
                    height="45px"
                  >
                    <SkeletonTextLoader
                      width={{ sm: "540px", md: "915px" }}
                      height="100%"
                    />
                  </SkeletonText>
                </Div>
              </Flex>
            </Div>
          </Div>
        </Div>
      </Flex>
    </Flex>
  );
};
