import React, { useState } from "react";
import PropTypes from "prop-types";
import { H7, H2 } from "app/components/Typography";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import {
  connectSearchBox,
  connectInfiniteHits,
  Configure,
  InstantSearch,
} from "react-instantsearch-dom";
import styled from "styled-components";
import Icon, { Search } from "app/components/Icon";
import Sentinel from "app/routes/Schedule/ForYou/SearchClasses/Sentinel";
import Loader from "app/components/Loader";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { searchClient } from "app/components/Algolia/constants";
import ClassRowResults from "./ClassRowResults";
import { RecommendedClasses } from "./RecommendedClasses";

const Input = styled.input`
  border: 0;
  width: 100%;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const Wrapper = styled(Flex)`
  .ais-InstantSearch__root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const LoaderOverlay = styled(Div)`
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.monochrome[8]};
  opacity: 0.7;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchBox = connectSearchBox(({ currentRefinement, refine }) => (
  <Flex
    width="100%"
    height="40px"
    borderRadius="4px"
    bg="white"
    borderColor="monochrome.1"
    overflow="hidden"
    mb={3}
  >
    <Flex justifyContent="center" height="100%" p={2}>
      <Icon height="100%" as={Search} color="black" />
    </Flex>
    <Input
      // eslint-disable-next-line quotes
      placeholder={'Try "popping"'}
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
  </Flex>
));

const Hits = connectInfiniteHits(
  ({
    addClassToSchedule,
    addedClassIds,
    loading,
    refineNext,
    hits,
    hasMore,
  }) => {
    return (
      <Div overflow="auto" height="100%" width="100%">
        {hits.map(classData => {
          const isClassAdded = addedClassIds?.includes(classData.id);

          return (
            <ClassRowResults
              key={classData.id}
              classData={classData}
              isClassAdded={isClassAdded}
              loading={loading}
              addClassToSchedule={addClassToSchedule}
            />
          );
        })}
        <Sentinel hits={hits} hasMore={hasMore} refineNext={refineNext} />
      </Div>
    );
  }
);

const SearchClasses = ({
  addClassToSchedule,
  addedClassIds,
  loading,
  selectedDateString,
}) => {
  const [searchState, setSearchState] = useState({});

  const isQueryFilled = searchState.query?.length > 0;
  const ClassesComponent = isQueryFilled ? Hits : RecommendedClasses;

  return (
    <Wrapper flexDirection="column" width="100%" height="100%">
      <H2 color="white" mb={3}>
        Add a class to {selectedDateString}&apos;s schedule
      </H2>
      <InstantSearch
        indexName={AlgoliaIndexes.classes}
        onSearchStateChange={updatedSearchState =>
          setSearchState(updatedSearchState)
        }
        searchClient={searchClient}
      >
        <Configure hitsPerPage={10} />
        <SearchBox />
        <H7 my={3} color="white">
          {isQueryFilled ? "Search Results" : "Recommended Classes"}
        </H7>
        <Div
          position="relative"
          width="100%"
          height="100%"
          minHeight={{ _: "100vh", lg: "unset" }}
        >
          {loading && (
            <LoaderOverlay>
              <Loader />
            </LoaderOverlay>
          )}
          <Div position="absolute" width="100%" height="100%">
            <ClassesComponent
              loading={loading}
              addClassToSchedule={addClassToSchedule}
              addedClassIds={addedClassIds}
            />
          </Div>
        </Div>
      </InstantSearch>
    </Wrapper>
  );
};

const defaultProps = {
  loading: true,
  addedClassIds: [],
};

const propTypes = {
  addClassToSchedule: PropTypes.func.isRequired,
  addedClassIds: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
};

SearchClasses.defaultProps = defaultProps;
SearchClasses.propTypes = propTypes;

export default SearchClasses;
