/* eslint-disable camelcase */
import React from "react";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import styled from "styled-components";
import abbreviateCount from "helpers/abbreviateCount";
import { useGetUserProgressQuery } from "services/graphql";

const StrokeText = styled(Text)`
  color: transparent;
  text-align: right;
  line-break: 1;
  font-weight: 800;
  -webkit-text-stroke: ${({ theme, textStroke }) => theme.colors[textStroke]}
    1px;
`;

const UserProgressCounts = () => {
  const { data: userData } = useGetUserProgressQuery({
    pollInterval: 5000,
  });
  const { user } = userData || {};

  return (
    <Flex display={{ _: "none", md: "flex" }}>
      <Flex flexDirection="column" mr={3}>
        <StrokeText fontSize="45px" textStroke="white">
          {abbreviateCount(
            Math.round(user?.progress?.total_time_spent / 60) || 0
          )}
        </StrokeText>
        <Text color="white" fontSize="12px">
          Total Minutes Spent
        </Text>
      </Flex>
      <Flex flexDirection="column">
        <StrokeText fontSize="45px" textStroke="white">
          {user?.progress?.classes_completed || 0}
        </StrokeText>
        <Text color="white" fontSize="12px">
          Classes Completed
        </Text>
      </Flex>
    </Flex>
  );
};

export default UserProgressCounts;
