import React, { useMemo, useCallback } from "react";
import moment from "moment";
import Flex from "app/components/Flex";
import { H2, P1 } from "app/components/Typography";
import Modal from "app/components/Modal";
import Button from "app/components/Button";
import {
  ModalHeader,
  ModalWrapper,
  ModalBody,
} from "app/components/Modal/components";
import { useDispatch, useSelector } from "react-redux";
import Img from "app/components/Img";
import { useFirebaseConnect } from "react-redux-firebase";
import _ from "lodash";
import {
  userSelectsProgramAction,
  userClickedRouteAction,
} from "modules/content";
import { setSeenModalAction } from "modules/progress";
import env from "helpers/env";

const tryGetIndex = (stringOrArray, i) => {
  if (!Array.isArray(stringOrArray)) {
    return stringOrArray;
  }
  return stringOrArray[i];
};

const AnnouncementModal = () => {
  const dispatch = useDispatch();
  useFirebaseConnect(["featured/modals"]);
  const modalInfoToDisplay = useSelector(
    ({ firebase }) => firebase.data?.featured?.modals
  );
  const { seenModals, activeSubscription } = useSelector(({ user }) => ({
    seenModals: user.progress?.content_progress?.modals,
    activeSubscription: user.subscription?.activeSubscription,
  }));

  const shouldShowModal = useCallback(
    info => {
      const { startDate, endDate, slug, userType } = info;
      // Modal timing check
      if (!moment().isBetween(startDate, endDate)) {
        return false;
      }
      // Seen modals check
      if (seenModals && seenModals[slug]) {
        return false;
      }
      // Required user type check
      if (userType === "paid" && !activeSubscription) {
        return false;
      }
      if (userType === "account_created" && activeSubscription) {
        return false;
      }
      // Every check passed so show this modal
      return true;
    },
    [activeSubscription, seenModals]
  );

  const infoToDisplay = _.find(modalInfoToDisplay, shouldShowModal);
  const modalImageSrc = `${env("PUBLIC_ASSET_PATH")}/release-banners/${
    infoToDisplay?.slug
  }.${infoToDisplay?.bannerFileType}?${
    infoToDisplay?.bannerFileType !== "gif" ? "w=600" : ""
  }`;

  const Cta = useMemo(() => {
    if (!infoToDisplay) {
      return null;
    }
    const ctaText = Array.isArray(infoToDisplay.buttonCtaText)
      ? infoToDisplay.buttonCtaText
      : [infoToDisplay.buttonCtaText];
    return () => (
      <>
        {ctaText.map((text, i) => {
          const action = tryGetIndex(infoToDisplay.buttonActionType, i);
          const programSlug = tryGetIndex(infoToDisplay.programSlug, i);
          const route = tryGetIndex(infoToDisplay.route, i);
          const slug = tryGetIndex(infoToDisplay.slug, i);
          return (
            <Button
              mt={3}
              onClick={() => {
                if (action === "START_PROGRAM") {
                  dispatch(
                    userSelectsProgramAction({
                      programSlug,
                      component: "AnnouncementModal",
                      element: "StartProgramButton",
                    })
                  );
                }
                if (action === "GO_TO_ROUTE") {
                  dispatch(
                    userClickedRouteAction({
                      path: route,
                      component: "AnnouncementModal",
                      element: "ModalCtaButton",
                    })
                  );
                }
                dispatch(setSeenModalAction({ slug }));
              }}
            >
              {text || "Got It"}
            </Button>
          );
        })}
      </>
    );
  }, [dispatch, infoToDisplay]);

  if (!infoToDisplay?.slug) {
    return null;
  }

  return (
    <Modal borderRadius="12px" maxWidth="400px" p={0} isOpen>
      <ModalWrapper>
        <Flex flexDirection="column" height="100%">
          <ModalHeader
            minHeight="fit-content"
            p={3}
            toggleModal={() =>
              dispatch(setSeenModalAction({ slug: infoToDisplay.slug }))
            }
          >
            <P1 color="white" textTransform="capitalize">
              {infoToDisplay?.headlineType}
            </P1>
            <H2 color="white">{infoToDisplay?.headlineText}</H2>
          </ModalHeader>
          <Img height="auto" width="100%" src={modalImageSrc} />
          <ModalBody p={3}>
            <P1>{infoToDisplay?.bodyText}</P1>
            <Cta />
          </ModalBody>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

export default AnnouncementModal;
