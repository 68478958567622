import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Div from "app/components/Div";
import _ from "lodash";
import useOnScreen from "hooks/useOnScreen";

const throttle = _.throttle(func => func(), 500);

const Sentinel = ({ hits = [], hasMore, refineNext, sentinel }) => {
  const ref = useRef();
  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    if (isOnScreen) {
      throttle(refineNext);
    }
  }, [isOnScreen, refineNext]);

  if (!hits.length || !hasMore) {
    return null;
  }

  return <Div height="8px" ref={sentinel ?? ref} />;
};

Sentinel.propTypes = {
  hits: PropTypes.instanceOf(Array).isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  sentinel: PropTypes.func,
};

export default Sentinel;
