/* eslint-disable camelcase */
import { useQuery } from "@apollo/client";
import AnnouncementModal from "app/components/AnnouncementModal";
import Flex from "app/components/Flex";
import WeeklyStreakModal from "app/components/WeeklyStreakModal";
import { DATE_FORMAT_FOR_SCHEDULES } from "constants/index";
import { SCHEDULE_HEADER, SCHEDULE_SUBHEADER } from "constants/schedule";
import { GET_CALENDAR_DATA_QUERY, GET_PLAYLIST_QUERY } from "graphql/queries";
import { userSelectsClassAction } from "modules/content";
import { setLastSeenWeeklyStreakAction } from "modules/progress";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAccountMode } from "hooks/useAccountMode";
import { debounce } from "lodash";
import Div from "app/components/Div";
import SearchClasses from "./SearchClasses";
import {
  useAddClassesForSchedule,
  useCreateRecommendedSchedulePlaylist,
  useRemoveTakenClass,
  useUpdateWeeklyStreak,
  useCreateSchedule,
  useAddedClasses,
} from "./hooks";
import AchievedWeeklyStreakModal from "./AchievedWeeklyStreakModal";
import Headline from "./Headline";
import { ScheduleEditor } from "./ScheduleEditor";
import { HeadlineSkeleton } from "./Skeleton/HeadlineSkeleton";
import { SchedulePreviewSkeleton } from "./Skeleton/SchedulePreviewSkeleton";
import UserProgressCounts from "./UserProgressCounts";
import { formatDateToString } from "../formatDateToString";

const isPlaylistCompletedAndNotSeen = playlistData =>
  playlistData?.progress?.completed &&
  !playlistData?.progress?.seen_completed_modal;

const ForYou = () => {
  // IMPROVEMENT: Check graphql caching
  // When switching between playlists with overlapping classes, the class-progress
  // of the previous playlist is used before new class-progress is updated
  const location = useLocation();
  const wrapperRef = useRef();
  const dispatch = useDispatch();
  const [selectedDate, setDate] = useState(moment());
  const [
    showAchievedWeeklyStreakModal,
    toggleAchievedWeeklyStreakModal,
  ] = useState(false);
  const [showWeeklyStreakModal, toggleWeeklyStreakModal] = useState(false);
  const isCurrentDate =
    moment().format(DATE_FORMAT_FOR_SCHEDULES) ===
    moment(selectedDate).format(DATE_FORMAT_FOR_SCHEDULES);
  const isBeforeCurrentDate = moment(selectedDate)
    .startOf("day")
    .isBefore(moment().startOf("day"));
  const isAfterCurrentDate = moment(selectedDate)
    .startOf("day")
    .isAfter(moment().endOf("day"));
  const isAfterMaxFutureDate = moment(selectedDate)
    .startOf("day")
    .isAfter(
      moment()
        .add(1, "year")
        .endOf("day")
    );
  const selectedDateString = formatDateToString(selectedDate);

  const isScheduleEditable = !isBeforeCurrentDate && !isAfterMaxFutureDate;
  const lastSeenWeeklyStreak = useSelector(
    ({ user }) =>
      user.progress?.content_progress?.for_you?.last_seen_weekly_streak
  );

  const { accountMode } = useAccountMode();

  // QUERIES
  const startOfSelectedDate = moment(selectedDate)
    .startOf("day")
    .format(DATE_FORMAT_FOR_SCHEDULES);

  const {
    loading: getPlaylistLoading,
    data: playlistData,
    called: hasGetPlaylistBeenCalled,
    refetch: refetchGetPlaylistQuery,
  } = useQuery(GET_PLAYLIST_QUERY, {
    variables: {
      date: startOfSelectedDate,
    },
    errorPolicy: "ignore",
  });
  const { playlist: selectedPlaylist } = playlistData || {};

  const { addedClasses } = useAddedClasses({ startOfSelectedDate });

  const {
    data: calendarDataQueryData,
    refetch: refetchGetCalendarDataQuery,
  } = useQuery(GET_CALENDAR_DATA_QUERY, {
    variables: {
      startDate: moment(selectedDate)
        .startOf("month")
        .format(DATE_FORMAT_FOR_SCHEDULES),
      endDate: moment(selectedDate)
        .endOf("month")
        .format(DATE_FORMAT_FOR_SCHEDULES),
    },
    errorPolicy: "ignore",
  });

  // MUTATIONS
  const [updateWeeklyStreakMutation] = useUpdateWeeklyStreak({
    refetchQueries: refetchGetCalendarDataQuery,
  });

  const [
    addClassesMutation,
    addClassesMutationLoading,
  ] = useAddClassesForSchedule();

  const [
    createRecommendedMutation,
    createRecommendedMutationLoading,
  ] = useCreateRecommendedSchedulePlaylist({
    refetchQueries: refetchGetPlaylistQuery,
    awaitRefetchQueries: true,
  });

  const [removeTakenClassMutation] = useRemoveTakenClass({
    refetchQueries: refetchGetPlaylistQuery,
    awaitRefetchQueries: true,
  });

  // VARIABLES FROM GQL
  const { calendarData } = calendarDataQueryData || {};

  const firstLoading = createRecommendedMutationLoading;

  const [
    createScheduleMutation,
    createScheduleMutationLoading,
  ] = useCreateSchedule();

  const loading = createScheduleMutationLoading || addClassesMutationLoading;

  const addClasses = debounce(classes => {
    const classIds = classes.map(c => String(c.id));
    addClassesMutation({
      variables: {
        id: selectedPlaylist.id,
        classIds,
        fromModule: "Playlist",
      },
    });
  }, 200);

  const createSchedule = classes => {
    const classIds = classes.map(c => String(c.id));
    createScheduleMutation({
      variables: {
        date: moment(selectedDate).format(DATE_FORMAT_FOR_SCHEDULES),
        classIds,
      },
      schedule: {
        startDate: moment(selectedDate)
          .startOf("month")
          .format(DATE_FORMAT_FOR_SCHEDULES),
        endDate: moment(selectedDate)
          .endOf("month")
          .format(DATE_FORMAT_FOR_SCHEDULES),
      },
    });
  };

  const createOrAddClassesToSchedule = classes => {
    if (selectedPlaylist) {
      addClasses(classes);
    } else {
      createSchedule(classes);
    }
  };

  const onUserSelectsClass = classData => {
    dispatch(
      userSelectsClassAction({
        classData,
        component: "Playlist",
        element: "StartClassButton",
        queryParams: {
          playlist: selectedPlaylist.id,
        },
        redirectToRouteOnEnd: "/dashboard",
        entryRoute: location.pathname,
        accountMode,
      })
    );
  };

  // LOCAL STATE
  const [isEditingMode, toggleEditingMode] = useState(false);
  const [showCompletedPlaylistModal, toggleCompletedPlaylistModal] = useState(
    false
  );

  const toggleEditPlaylistMode = toggle => {
    toggleEditingMode(toggle);
  };

  useEffect(() => {
    if (isPlaylistCompletedAndNotSeen(selectedPlaylist)) {
      toggleCompletedPlaylistModal(true);
    }
  }, [selectedPlaylist]);

  useEffect(() => {
    const currentWeeklyStreakCount = parseInt(calendarData?.weeklyStreakCount);
    const parsedLastSeenWeeklyStreak = parseInt(lastSeenWeeklyStreak);
    if (
      parsedLastSeenWeeklyStreak !== currentWeeklyStreakCount &&
      currentWeeklyStreakCount >= 1
    ) {
      toggleAchievedWeeklyStreakModal(true);
    } else if (currentWeeklyStreakCount === 0) {
      dispatch(setLastSeenWeeklyStreakAction(0));
    }
  }, [calendarData?.weeklyStreakCount, dispatch, lastSeenWeeklyStreak]);

  useEffect(() => {
    // Want to create recommended classes schedule if today and nothing has been created yet
    if (
      !getPlaylistLoading &&
      hasGetPlaylistBeenCalled &&
      playlistData &&
      !playlistData.playlist &&
      isCurrentDate
    ) {
      createRecommendedMutation({
        variables: {
          date: startOfSelectedDate,
        },
      });
    }
  }, [
    createRecommendedMutation,
    getPlaylistLoading,
    hasGetPlaylistBeenCalled,
    isCurrentDate,
    playlistData,
    startOfSelectedDate,
  ]);

  return (
    <>
      <Flex flexDirection={{ _: "column", lg: "row" }}>
        <Flex flexDirection="column" width={{ _: "100%", lg: "70%" }}>
          <Flex
            pt={{ _: 2, lg: 4 }}
            pb={{ _: 3, lg: 4 }}
            bg="black"
            flexDirection={{ _: "column", lg: "row" }}
            ref={wrapperRef}
          >
            <Flex
              px={{ _: 3, lg: 4 }}
              pt={{ _: 0, lg: 1 }}
              pb={{ _: 3, lg: 2 }}
              alignItems={{ _: "flex-start", md: "center" }}
              justifyContent="space-between"
              flexDirection={{ _: "column", md: "row" }}
              borderColor="monochrome.6"
              width="100%"
            >
              {firstLoading ? (
                <HeadlineSkeleton />
              ) : (
                <Headline
                  header={SCHEDULE_HEADER}
                  subheader={SCHEDULE_SUBHEADER}
                />
              )}
              <UserProgressCounts />
            </Flex>
          </Flex>
          <Flex
            position="relative"
            bg="black"
            border="1px solid"
            borderColor="monochrome.7"
            width="100%"
          >
            <Div width="100%" pb={{ lg: "56.25%" }}>
              <Div
                width="100%"
                height="100%"
                position={{ lg: "absolute" }}
                zIndex="1"
                top={0}
              >
                {firstLoading ? (
                  <SchedulePreviewSkeleton />
                ) : (
                  <ScheduleEditor
                    onUserSelectsClass={onUserSelectsClass}
                    isEditingMode={isEditingMode}
                    setDate={setDate}
                    selectedDate={selectedDate}
                    toggleWeeklyStreakModal={toggleWeeklyStreakModal}
                    calendarData={calendarData}
                    isScheduleEditable={isScheduleEditable}
                    addedClasses={addedClasses}
                    showCompletedPlaylistModal={showCompletedPlaylistModal}
                    toggleCompletedPlaylistModal={toggleCompletedPlaylistModal}
                    selectedPlaylist={selectedPlaylist}
                    toggleEditPlaylistMode={toggleEditPlaylistMode}
                    removeTakenClassMutation={removeTakenClassMutation}
                    isAfterCurrentDate={isAfterCurrentDate}
                    wrapperRef={wrapperRef}
                  />
                )}
              </Div>
            </Div>
          </Flex>
        </Flex>
        <Flex
          width={{ _: "100%", lg: "30%" }}
          bg="monochrome.7"
          position="relative"
          flexDirection="column"
          justifyContent="space-between"
          p="16px 16px 24px"
        >
          {isScheduleEditable && (
            <SearchClasses
              selectedDateString={selectedDateString}
              loading={loading}
              addClassToSchedule={classData => {
                createOrAddClassesToSchedule([classData]);
              }}
              addedClassIds={addedClasses?.classes.map(c => parseInt(c.id))}
            />
          )}
        </Flex>
      </Flex>
      <AchievedWeeklyStreakModal
        isOpen={showAchievedWeeklyStreakModal}
        closeModal={() => toggleAchievedWeeklyStreakModal(false)}
        weeklyStreakCount={calendarData?.weeklyStreakCount || 0}
      />
      <WeeklyStreakModal
        currentGoal={calendarData?.weeklyStreakGoal}
        updateGoal={streakGoal =>
          updateWeeklyStreakMutation({
            variables: { streakGoal },
          })
        }
        isOpen={showWeeklyStreakModal}
        toggleModal={toggleWeeklyStreakModal}
      />
      <AnnouncementModal />
    </>
  );
};

export default ForYou;
